import { defineStore } from 'pinia'

export const usePageArtistStore = defineStore('PageArtistStore', {
	state: () => ({
		artist: null,
	}),
	actions: {
		// Load artist
		async storeArtist(artist) {
			this.artist = artist
		},

		// Clear artwork & artist data
		clearStore() {
			this.artist = null
		},
	},
})
