<template>
	<div class="content-pad narrow">
		<h1 id="title">
			Edit Artist &nbsp;<em>{{ displayName }}</em>
		</h1>

		<DebugDisplay
			v-if="debug && (debug == 1 || debug == 3)"
			:data="artistEditData"
			style="margin:0.2rem 0"
		/>
		<DebugDisplay v-if="debug && (debug == 1 || debug == 3)" :data="errors" style="margin:0.2rem 0" />

		<!-- Form -->
		<div id="form" class="structured-form two-cols">
			<FormDropdown
				v-model="artistEditData.category"
				label="Category"
				:options="ddCategory"
				:debug="debug && debug > 1"
			/>
			<FormDropdown
				v-model="artistEditData.level"
				label="Level"
				:options="ddLevel"
				:debug="debug && debug > 1"
			/>

			<h2 class="full-width">Name</h2>

			<!-- Name -->
			<div v-if="errors.foreignMainName" id="foreign-name-error" class="error-msg full-width">
				<p>
					The main artist name should use Latin script. You can add the original name as an
					<a href="@" @click.prevent="addAltName">alternative name</a> (both will be displayed).
				</p>
				<p>This is to ensure the artist's searchability and accessibility.</p>
			</div>
			<FormText
				:label="nameSingular ? 'Name / Pseudonym' : 'First Name'"
				v-model="name"
				:error="nameSingular ? errors.name : errors.firstName"
				toggleName="Singular"
				@update="
					val => {
						nameSingular ? onUpdateName(val) : onUpdateFirstName(val)
					}
				"
				v-model:toggleValue="nameSingular"
				@update:toggleValue="toggleSingular"
			/>
			<FormText
				v-if="!nameSingular"
				v-model="artistEditData.lastName"
				:error="errors.lastName"
				@update="onUpdateLastName"
				label="Last Name"
			/>
			<div v-else class="empty-slot"></div>

			<!-- Alt names -->
			<template v-for="(altName, i) in artistEditData.altNames" :key="i">
				<template v-if="altName.singular">
					<FormText
						label="AKA: Name / Pseudonym"
						toggleName="Singular"
						v-model="altName.name"
						v-model:toggleValue="altName.singular"
						:autoFocus="!altName.name"
						@blur="e => onBlurAltName(i, e)"
						@update:toggleValue="val => toggleSingular(val, altName, i)"
					/>
					<div class="empty-slot"></div>
				</template>
				<template v-else>
					<FormText
						label="AKA: First Name"
						toggleName="Singular"
						v-model="altName.firstName"
						v-model:toggleValue="altName.singular"
						:error="errors[`altName-${i}-first`]"
						:data-index="i"
						:autoFocus="!altName.firstName"
						@update="delete errors[`altName-${i}-first`]"
						@blur="e => onBlurAltName(i, e)"
						@update:toggleValue="val => toggleSingular(val, altName, i)"
					/>
					<FormText
						v-model="altName.lastName"
						:error="errors[`altName-${i}-last`]"
						@update="delete errors[`altName-${i}-last`]"
						@blur="e => onBlurAltName(i, e)"
						:data-index="i"
						label="AKA: Last Name"
					/>
				</template>
			</template>
			<a v-if="showAddAltName" href="#" class="extra" @click.prevent="addAltName">+ Also known as</a>

			<h2 class="full-width">About</h2>

			<!-- Born / Died -->
			<FormYear
				label="Born"
				v-model="artistEditData.born"
				@error="onErrorBorn"
				:debug="debug && debug > 1"
			/>
			<FormYear
				label="Died"
				v-model="artistEditData.died"
				@error="onErrorDied"
				:error="errors.died"
				:debug="debug && debug > 1"
			/>

			<!-- Country -->
			<FormText v-model="artistEditData.country" label="Country of Origin" />

			<!-- About -->
			<FormText
				v-model="artistEditData.about"
				label="About"
				type="textarea"
				class="about full-width no-gap"
			/>

			<template v-for="(url, i) in artistEditData.aboutSources" :key="i">
				<FormUrl
					v-model="artistEditData.aboutSources[i]"
					placeholder="Source"
					class="about-src full-width"
					:autoFocus="!url && i > 0"
					:class="{
						'no-gap': i < artistEditData.aboutSources.length - 1,
					}"
					@update="data => onUpdateAboutSrc(i, data)"
					:debug="debug && debug > 1"
				/>
			</template>
			<a
				v-if="artistEditData.aboutSources[artistEditData.aboutSources.length - 1]"
				href="#"
				class="extra"
				@click.prevent="addAboutSrc"
				>+ Add source</a
			>
			<div class="full-width"></div>

			<!-- Website -->
			<FormUrl
				v-model="artistEditData.website"
				label="Personal Website"
				@update="onUpdateWebsite"
				:debug="debug && debug > 1"
			/>

			<!-- Wikipedia -->
			<FormUrl
				v-model="artistEditData.wikipedia"
				label="Wikipedia"
				placeholder="https://en.wikipedia.org/wiki/..."
				:customModifier="trimWikipediaUrl"
				:customValidator="validateWikipediaUrl"
				@update="onUpdateWikipedia"
				:debug="debug && debug > 1"
			/>

			<!-- Other links -->
			<template v-for="(url, i) in artistEditData.otherLinks" :key="i">
				<FormUrl
					v-if="url != '_EMPTY_'"
					:key="i"
					v-model="artistEditData.otherLinks[i]"
					label="Other Link"
					:autoFocus="!url"
					:markEmpty="true"
					@update="data => onUpdateLink(i, data)"
					:debug="debug && debug > 1"
				/>
			</template>
			<div v-if="showAddLinkEmptySlot" class="empty-slot"></div>
			<div v-if="showAddLink" class="extra">
				<a href="#" @click.prevent="addLink">+ Add link</a>
				<span class="small soft"> &nbsp;-&nbsp; Instagram, Twitter, website...</span>
			</div>

			<h2 class="full-width">Related Artists</h2>
			<p>Submissions are moderated, please avoid shameless self promotion.</p>

			<!-- Related -->
			<template v-if="artistEditData.related">
				<template v-for="(artist, i) in artistEditData.related" :key="i">
					<!-- Loading UI while we load artist names -->
					<template v-if="artist.id && !artist.name">
						<div v-if="i === 0" class="related-artist-loading-label">Related Artists</div>
						<div class="related-artist-loading">Loading...</div>
					</template>

					<template v-else-if="artist.name || artist.inputValue">
						<FormArtist
							:initialData="artist"
							:label="i == 0 ? 'Related Artists' : null"
							class="related-artist-ip small-gap"
							@update="(artistId, valid) => updateRelated(i, artistId, valid)"
							:debug="debug && debug > 1"
						/>
						<FormDropdown
							v-model="artist.connection"
							:error="errors[`related-connection-${i}`]"
							:label="i == 0 ? 'Connection' : null"
							:options="ddRelatedConnection"
							class="related-artist-dd small-gap"
							:debug="debug && debug > 1"
						/>
					</template>
				</template>
			</template>
			<FormArtist
				ref="addRelated"
				:label="(artistEditData.related || []).length ? '' : 'Related Artist'"
				placeholder="+ Add related artist"
				@update="addRelated"
				class="full-width"
			/>

			<div id="buttons">
				<!-- Error message -->
				<div v-if="errorMsg" id="error-msg" v-html="errorMsg"></div>

				<!-- Submit -->
				<FormButton :value="['Save', 'Saving']" :wait="isSubmitted" @click="onSubmit" /><FormButton
					:level="2"
					value="Cancel"
					@click="$emit('exit')"
				/>
			</div>
		</div>

		<DebugDisplay
			v-if="debug && (debug == 1 || debug == 3)"
			:data="artistEditData"
			style="margin:0.2rem 0"
		/>
		<DebugDisplay v-if="debug && (debug == 1 || debug == 3)" :data="errors" style="margin:0.2rem 0" />
	</div>
</template>

<script>
// Stores
import { useApiStore } from '@/stores/ApiStore'

// Components
import FormArtist from '@/components/FormArtist'
import FormText from '@/components/FormText'
import FormYear from '@/components/FormYear'
import FormUrl from '@/components/FormUrl'
import FormDropdown from '@/components/FormDropdown'
import FormButton from '@/components/FormButton'
import DebugDisplay from '@/components/DebugDisplay'

// Internal
import artCategories from '@/use/ArtCategories'
import isNonLatin from '@/use/NonLatin'
import { cloneDeep } from '@/use/Helpers'
import { removeUnchangedValues } from '@/use/General'

export default {
	name: 'ArtistEdit',
	components: {
		FormArtist,
		FormText,
		FormYear,
		FormUrl,
		FormDropdown,
		FormButton,
		DebugDisplay,
	},
	props: {
		artist: Object,
	},
	setup() {
		const apiStore = useApiStore()
		const artistApi = apiStore.loadApi('artist')
		return { artistApi }
	},
	data() {
		return {
			// Shows debug displays (1/2/3)
			debug: this.$route.query.debug,

			// Artist data clone where we store our edits.
			artistEditData: this.initArtistEditData(this.artist),

			// Centralized input errors.
			errors: {},

			// Error message that is rendered via computed errorMsg.
			mainError: null,

			// Counter of errors used in errorMsg.
			errorCount: 0,

			// Whether we store the as a single string of first+last name.
			nameSingular: !(this.artist.firstName && this.artist.lastName),

			// Triggers the waiting state for the submit button.
			isSubmitted: false,

			// Category dropdown
			ddCategory: artCategories.dropdown(),

			// Level dropdown
			// unknown/established/well-known/famous
			ddLevel: [
				{
					value: 1,
					display: 'Under the radar',
				},
				{
					value: 2,
					display: 'Established',
				},
				{
					value: 3,
					display: 'Well known',
				},
				{
					value: 4,
					display: 'Famous / Historic',
				},
			],

			// Related artist connection dropdown
			ddRelatedConnection: [
				{
					value: 'similar',
					display: 'Similar',
				},
				{
					value: 'contemporaries',
					display: 'Contemporaries',
				},
				{
					value: 'family',
					display: 'Family',
				},
				{
					value: 'other',
					display: 'Other',
				},
			],
		}
	},
	computed: {
		// Conditional v-model for main name.
		name: {
			get() {
				return this.nameSingular ? this.artistEditData.name : this.artistEditData.firstName
			},
			set(val) {
				if (this.nameSingular) {
					this.artistEditData.name = val
				} else {
					this.artistEditData.firstName = val
				}
			},
		},

		// Name displayed in the title.
		displayName() {
			return this.nameSingular
				? this.name
				: this.artistEditData.firstName + ' ' + this.artistEditData.lastName
		},

		// Only show "+ Also known as" link when the last name has a value.
		showAddAltName() {
			return (this.artistEditData.altNames || []).length < 2
		},

		// Whether to show the "+ Add link" link.
		showAddLink() {
			// For info about the use of _EMPTY_ see FormUrl.vue.
			const links = (this.artistEditData.otherLinks || []).filter(url => url != '_EMPTY_')
			if (!links.length) return true
			const maxedOut = links.length >= 4
			return !maxedOut
		},

		// Empty slot ensures the "+ Add link" link show below.
		showAddLinkEmptySlot() {
			const linkCount = (this.artistEditData.otherLinks || []).filter(url => url != '_EMPTY_').length
			return Math.round(linkCount / 2) != linkCount / 2
		},

		// Used to watch for changes and reset errorMsg.
		stringifiedData() {
			return JSON.stringify(this.artistEditData)
		},

		// Error message at the bottom.
		errorMsg() {
			if (this.mainError) {
				return this.mainError
			} else if (!this.errorCount) {
				return null
			} else if (this.errorCount == 1) {
				return 'There is one error, scroll up to see details.'
			} else {
				return `There are ${this.errorCount} errors, scroll up to see details.`
			}
		},
	},
	watch: {
		// Reset error message whenever data changes.
		stringifiedData() {
			this.resetErrorMsg()
		},
	},
	methods: {
		// Reduce artist object to only the relevant fields for editing
		initArtistEditData(artist) {
			const keys =
				'id category level name firstName lastName displayName altNames born died country about aboutSources website wikipedia otherLinks related'
			const clone = cloneDeep(artist, keys)

			// If first & last name fields are present, the "name" field
			// is simply a merged first + last name we use for display.
			// We remove this field because it's duplicated data, will be
			// recreated on submit.
			if (clone.firstName && clone.lastName) {
				clone.name = undefined
			}

			// Set singular toggle for every alt name.
			if (clone.altNames) {
				clone.altNames = clone.altNames.map(altName => {
					if (altName.name) {
						altName.singular = true
					} else {
						altName.singular = false
					}
					return altName
				})
			}

			// If no aboutSources are present, add a blank one
			// so an empty field is displayed.
			clone.aboutSources = clone.aboutSources && clone.aboutSources.length ? clone.aboutSources : ['']

			return clone
		},

		// When switching between singular & first+last name, we split/merge the names.
		toggleSingular(value, altName, i) {
			let singular = value
			// console.log(66, value, altName, altName.firstName, this.firstName)
			let name = altName ? altName.name : this.artistEditData.name
			let firstName = altName ? altName.firstName : this.artistEditData.firstName
			let lastName = altName ? altName.lastName : this.artistEditData.lastName

			// Split or merge name
			if (singular) {
				name = (firstName.trim() + ' ' + lastName.trim()).trim()
			} else {
				const firstLast = name
					.replace(/\s+/, ' ')
					.trim()
					.split(' ')
				lastName = firstLast.pop()
				firstName = firstLast.join(' ')
			}

			// Propagate
			if (singular) {
				if (altName) {
					altName.name = name
					altName.firstName = undefined
					altName.lastName = undefined
				} else {
					this.artistEditData.name = name
					this.artistEditData.firstName = undefined
					this.artistEditData.lastName = undefined
				}
			} else {
				if (altName) {
					altName.firstName = firstName
					altName.lastName = lastName
					altName.name = undefined
				} else {
					this.artistEditData.firstName = firstName
					this.artistEditData.lastName = lastName
					this.artistEditData.name = undefined
				}
			}

			// Reset error
			if (altName) {
				delete this.errors[`altName-${i}-first`]
				delete this.errors[`altName-${i}-last`]
			} else {
				delete this.errors.firstName
				delete this.errors.lastName
			}
		},

		// Update name values
		onUpdateName(name) {
			this.errors.foreignMainName = isNonLatin(name, { fully: true }) || undefined
			delete this.errors.name
		},
		onUpdateFirstName(firstName) {
			console.log(firstName, isNonLatin(firstName, { fully: true })) || undefined
			this.errors.foreignMainName = isNonLatin(firstName, { fully: true }) || undefined
			delete this.errors.firstName
		},
		onUpdateLastName(lastName) {
			this.errors.foreignMainName = isNonLatin(lastName, { fully: true }) || undefined
			delete this.errors.lastName
		},

		// Add alternative name
		addAltName() {
			this.artistEditData.altNames = this.artistEditData.altNames || []
			this.artistEditData.altNames.push({
				firstName: '',
				lastName: '',
				singular: false,
			})
		},

		// Remove name input if it's empty.
		onBlurAltName(i, e) {
			// Don't remove the input when we're clickig the singular toggle, or the other first/last name input.
			const isToggle = e.relatedTarget && e.relatedTarget.classList.contains('ip-toggle-wrap')
			const isRelated = _isDescendant(e.target.parentNode, e.relatedTarget)
			const toggleClicked = isToggle && isRelated
			const isSibling = _isSibling(e.target, e.relatedTarget)

			// Hide inputs when empty.
			if (!toggleClicked && !isSibling) {
				const altName = this.artistEditData.altNames[i]
				const isEmpty = !altName.name && !altName.firstName && !altName.lastName
				if (isEmpty) this.artistEditData.altNames.splice(i, 1)
			}

			//
			//

			// Check if 'child' is a descendant of 'parent'.
			function _isDescendant(parent, child) {
				if (!parent || !child) return false
				let node = child.parentNode
				while (node) {
					if (node === parent) return true
					node = node.parentNode
				}
				return false
			}

			// Check if we're going from first to last or vide versa.
			function _isSibling(target, relatedTarget) {
				return !!(
					target &&
					target.parentNode &&
					relatedTarget &&
					relatedTarget.parentNode &&
					target.parentNode.getAttribute('data-index') ==
						relatedTarget.parentNode.getAttribute('data-index')
				)
			}
		},

		// Born/died error changes
		onErrorBorn(error) {
			this.errors.born = error || undefined
			this.resetErrorMsg()
		},
		onErrorDied(error) {
			this.errors.died = error || undefined
			this.resetErrorMsg()
		},

		addAboutSrc() {
			this.artistEditData.aboutSources = this.artistEditData.aboutSources || []
			this.artistEditData.aboutSources.push('')
		},

		// Add valid source links to the main data object.
		onUpdateAboutSrc(i, data) {
			const { valid, value } = data

			// Set error
			this.errors[`aboutSources-${i}`] = value ? (!valid ? true : undefined) : undefined
		},

		// Update website
		onUpdateWebsite(data) {
			const { valid, value } = data

			// Set error
			this.errors.website = value ? (!valid ? true : undefined) : undefined
		},

		// Remove possible hash from wikipedia url.
		trimWikipediaUrl(url) {
			url = url.split('#')[0]
			url = url.split('?')[0]
			return url.split('#')[0]
		},

		// Make sure URL is valid wikipedia url.
		validateWikipediaUrl(url) {
			return !!url.match(/^http(s)?:\/\/\w+\.wikipedia\.org\/wiki\/[-_\w:.+()]+$/)
		},

		// Update wikipedia
		onUpdateWikipedia(data) {
			const { valid, value } = data

			// Set error
			this.errors.wikipedia = value ? (!valid ? true : undefined) : undefined
		},

		// Add link
		addLink() {
			this.artistEditData.otherLinks = this.artistEditData.otherLinks || []
			this.artistEditData.otherLinks.push('')
		},

		// Update link
		onUpdateLink(i, data) {
			const { valid, value } = data

			// Set error
			this.errors[`otherLinks-${i}`] = value ? (!valid ? true : undefined) : undefined
		},

		// Add related artist
		addRelated(artist) {
			const { name, id, inputValue, valid } = artist || {}
			this.artistEditData.related = this.artistEditData.related || []

			if (valid) {
				// Add artist
				this.artistEditData.related.push({ name, id })

				// Empty input
				this.$refs.addRelated.clear()

				// Remove error
				delete this.errors.relatedInput
			} else {
				// Set or remove error message
				this.errors.relatedInput = inputValue ? true : undefined
			}
		},

		// Add related artist
		updateRelated(i, artist) {
			const { name, id, inputValue, error } = artist || {}
			this.artistEditData.related[i] = { name, id, inputValue }

			// Set error
			this.errors[`related-${i}`] = !!error || undefined
		},

		// Update errorCount
		countErrors() {
			// Remove undefined values, which shoulnd't trigger errorCount
			Object.keys(this.errors).forEach(key =>
				this.errors[key] === undefined ? delete this.errors[key] : {}
			)
			return Object.keys(this.errors).length
		},

		// Reset error message at the bottom.
		resetErrorMsg() {
			this.mainError = null
			this.errorCount = null
		},

		// Validate form
		validate() {
			const data = this.artistEditData

			// Make sure there's no empty name/firstName/lastName
			if (this.nameSingular) {
				if (!data.name.trim()) this.errors.name = 'Required'
			} else if (!data.firstName.trim()) {
				this.errors.firstName = 'Missing'
			} else if (!data.lastName.trim()) {
				this.errors.lastName = 'Missing'
			}

			// Maker sure there's no empty altNames
			if (data.altNames) {
				data.altNames.forEach((altName, i) => {
					const { name, firstName, lastName } = altName
					if (firstName && !lastName.trim()) {
						this.errors[`altName-${i}-last`] = 'Missing'
					} else if (lastName && !firstName.trim()) {
						this.errors[`altName-${i}-first`] = 'Missing'
					} else if (name && !name.trim()) {
						// Edge case where value is whitespace --> remove altName.
						data.altNames.splice(i, 1)
					}
				})
			}

			// Make sure 'died' date is after 'born' date.
			if (data.born && data.died && data.died[0] < data.born[0]) {
				this.errors.died = 'Before Birth'
			}

			// Make sure every related artist is valid and has a connection set.
			if (data.related) {
				data.related.forEach((artist, i) => {
					const connectionMissing = !artist.connection && artist.id
					this.errors[`related-connection-${i}`] = connectionMissing ? 'Required' : undefined
				})
			}

			// Return error Boolean.
			this.errorCount = this.countErrors()
			return this.errorCount === 0
		},

		// Format data for API
		formatData() {
			let uploadData = { ...this.artistEditData }

			// Remove any values that didn't change.
			uploadData = removeUnchangedValues(uploadData, this.artist)

			// Turn category into an array (todo: allow secondary category)
			if (uploadData.category) uploadData.category = [uploadData.category]

			// Set foreignName when one of the alt names consists entirely of foreign characters.
			// This will then cause the artist's name to be displayed in English and in their native script.
			// Eg. Etel Adnan ~ إيتيل عدنان
			if (uploadData.altNames) {
				for (let i = 0; i < uploadData.altNames.length; i++) {
					const { name, firstName, lastName } = uploadData.altNames[i]
					if (isNonLatin(name, { fully: true })) {
						uploadData.foreignName = name
						break
					} else if (
						isNonLatin(firstName, { fully: true }) &&
						isNonLatin(lastName, { fully: true })
					) {
						uploadData.foreignName = `${firstName} ${lastName}`
					}
				}
			}
			// The foreignName is recreated on every edit, so when the
			// foreign altName is removed, the foreignName is also removed.
			uploadData.foreignName = uploadData.foreignName || null

			// Remove empty url values.
			if (uploadData.aboutSources) {
				uploadData.aboutSources = uploadData.aboutSources.filter(url => url != '_EMPTY_' && url != '') // See ['']
			}
			if (uploadData.otherLinks) {
				uploadData.otherLinks = uploadData.otherLinks.filter(url => url != '_EMPTY_')
			}

			// Remove empty related artists and reduce the list to id + connection.
			if (uploadData.related) {
				uploadData.related = uploadData.related
					.filter(artist => !!artist.id)
					.map(artist => ({
						id: artist.id,
						connection: artist.connection,
					}))
			}

			// Remove any empty arrays.
			if (uploadData.related && !uploadData.related.length) uploadData.related = null
			if (uploadData.altNames && !uploadData.altNames.length) uploadData.altNames = null
			if (uploadData.aboutSources && !uploadData.aboutSources.length) uploadData.aboutSources = null

			return uploadData
		},

		// Submit to API
		async onSubmit() {
			// TODO: use $emit instead of serparate API call, model after ClusterEdit
			const valid = this.validate()
			if (valid) {
				const uploadData = this.formatData()
				console.log('Submitted data:\n', uploadData)
				const { status, data, statusText } = await this.artistApi.update(uploadData)
				if (status == 200) {
					this.isSubmitted = true
					this.$emit('update', data)
				} else {
					this.mainError = statusText ? statusText : 'Something went wrong...'
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
#title {
	margin-bottom: 0.4rem;
}
#form .buttons-top {
	margin-bottom: 0.3rem;
}
#form h2 {
	// margin-top:0.4rem;
	margin: 0.3rem 0 0.3rem 0;
}
#form h2 + p {
	margin: -0.2rem 0 0.3rem 0;
}

// + Add xyx links
.extra {
	margin-top: -0.15rem;
	margin-bottom: 0.3rem;
	font-size: $small;
	line-height: $small-line-height;
}

// Elements with0.05rem gaps
.wrap.no-gap {
	margin-bottom: 0.05rem;
}

// Foreign name error
#foreign-name-error {
	background: $bad-soft;
	padding: 0.2rem;
	padding-bottom: 0.1rem;
	border-radius: $br;
	margin-bottom: 0.2rem;
}

// About
.about {
	height: 4rem;
}

// Related artists
.related-artist-loading-label {
	height: 0.12rem;
	line-height: 0.12rem;
	margin-bottom: 0.08rem;
	width: 100%;
	font-size: $small;
}
.related-artist-loading {
	height: 0.4rem;
	line-height: 0.4rem;
	padding: 0 0.13rem;
	background: $black-05;
	border-radius: $br;
	width: 100%;
	margin-bottom: 0.05rem;
}
.related-artist-ip:deep() .wrap {
	width: 3.75rem;
}
.related-artist-dd {
	width: 1.6rem;
}
.small-gap {
	margin-bottom: 0.1rem;
}

// Error message
#error-msg {
	color: $bad;
	line-height: 0.2rem;
	margin-top: -0.2rem;
	margin-bottom: 0.2rem;
	animation: blink 170ms 3;
}

#buttons {
	margin-top: 0.3rem;
}

// 660
@media only screen and (max-width: $form) {
	#form {
		width: auto;
	}
	#form:deep() .wrap {
		width: 100%;
	}
	#form .separator {
		width: 100%;
		flex-basis: 0.01rem;
	}
}
</style>
