<template>
	<FloatActions
		:menuOptions="editMode ? null : menuOptions"
		:rightAlign="editMode"
		:closeBtn="editMode"
		@close="toggleEditMode"
		:responsive="{ mobile: -0.7 }"
	/>
	<template v-if="loading">
		<div id="header">
			<h1>Loading...</h1>
		</div>
	</template>

	<!-- Artist fallback conflict - Try with http://localhost:8080/photography/shannon-wright -->
	<ArtistFallbackConflict v-else-if="showConflict" :data="conflictData" />

	<!-- Artist Page -->
	<template v-if="artist && !loading">
		<!-- Edit mode -->
		<ArtistEdit
			v-if="editMode && $isLoggedIn"
			:artist="artist"
			@exit="toggleEditMode"
			@update="onArtistUpdate"
		/>

		<div v-show="!(editMode && $isLoggedIn)">
			<AdminControls v-if="$isAdmin" entityType="artist" :entity="artist" />

			<!-- Header -->
			<div id="header" class="small" itemscope itemtype="https://schema.org/Person" itemprop="artist">
				<!-- Name -->
				<h1 itemprop="name">
					{{ artist.name
					}}<template v-if="artist.foreignName"><em> ~</em> {{ artist.foreignName }}</template>
				</h1>
				<div class="aka" v-if="altNames" v-html="'Also known as ' + altNames"></div>

				<!-- Takedown notice -->
				<template v-if="artist.takenDown">
					<div>
						<p>{{ hiddenArtistNote }}</p>
						<p>
							You can still see their work on
							<a target="_blank" :href="googleUrl">Google</a>,
							<a target="_blank" :href="tumblrUrl">Tumblr</a> or
							<a target="_blank" :href="pinterestUrl">Pinterest</a>
						</p>
					</div>
				</template>

				<!-- Artist info -->
				<template v-else>
					<!-- Byline -->
					<div v-if="byline" v-html="byline" class="byline"></div>

					<!-- Copyright -->
					<div v-if="artist.artworkCount > 0 || byline" class="notice">
						<p>
							&copy; {{ artist.name
							}}<template v-if="artist.foreignName"> ~ {{ artist.foreignName }}</template>
						</p>
						The content on this page is aggregated<br />and is not affiliated with the artist.
					</div>
					<div v-else class="notice"></div>

					<!-- About -->
					<div v-if="artist.about" class="about" :class="aboutClass" itemprop="about">
						{{ artist.about }}

						<span v-html="aboutSources"></span>

						<br /><br /><a v-if="artist.artworkCount > 0" target="_blank" :href="googleUrl"
							>More on Google</a
						>
					</div>
					<a v-else-if="artist.artworkCount > 0" target="_blank" :href="googleUrl"
						>More on Google</a
					>

					<!-- Related artists -->
					<div v-if="artist.related && artist.related.length" class="related">
						Related artists:
						<template v-for="(a, i) in artist.related" :key="a.id">
							<!-- {{ a.namePath }} -->
							<router-link
								v-if="a.namePath"
								:to="{ name: 'Artist', params: { namePath: a.namePath } }"
								>{{ a.name }}</router-link
							>
							<template v-if="i < artist.related.length - 1">, </template>
						</template>
					</div>
				</template>
			</div>

			<!-- Gallery -->
			<template v-if="artist.artworkCount > 0 && !artist.takenDown">
				<TheArtGrid entityType="artist" :id="artist.id" />
				<TheWormhole v-if="!loading && !feedStore.loading" />
			</template>

			<!-- Empty gallery -->
			<div v-else id="gallery-empty">
				<template v-if="!artist.takenDown">
					We don't have any artworks by {{ artist.name }}.<br />Try
					<a target="_blank" :href="googleUrl">Google</a>,
					<a target="_blank" :href="tumblrUrl">Tumblr</a> or
					<a target="_blank" :href="pinterestUrl">Pinterest</a> or...
				</template>
				<TheWormhole v-if="!loading" />
			</div>
		</div>
	</template>
</template>

<script>
// Vue
import { computed, watch, onServerPrefetch, onMounted, onBeforeUnmount, reactive, toRefs, toRef } from 'vue'
import { useRouter, useRoute } from 'vue-router'

// Stores
import { usePageArtistStore } from '@/stores/PageArtistStore'
import { useApiStore } from '@/stores/ApiStore'
import { useHttpErrorStore } from '@/stores/HttpErrorStore'
import { useFeedStore } from '@/stores/FeedStore'

// Views
import ArtistEdit from '@/views/Artist/ArtistEdit'

// Components
import FloatActions from '@/components/FloatActions'
import AdminControls from '@/components/AdminControls/AdminControls'
import TheArtGrid from '@/components/TheArtGrid'
import TheWormhole from '@/components/TheWormhole'
import ArtistFallbackConflict from '@/components/ArtistFallbackConflict'

// Internal
import flash from '@/use/FlashAlert'
import { injectMetaData } from '@/use/MetaData'
import { isSSRClient } from '@/use/Base'
import { possessive, displayUrl } from '@/use/StringMagic'
import { google, tumblr, pinterest } from '@/use/ExternalLinks'
import displayYear from '@/use/DisplayYear'
import { handleApiError } from '@/use/Helpers'

export default {
	name: 'ArtistMain',
	components: {
		FloatActions,
		ArtistEdit,
		AdminControls,
		TheArtGrid,
		TheWormhole,
		ArtistFallbackConflict,
	},
	props: {
		namePath: {
			type: String,
			required: true,
		},
		// art
		// photography
		// illustration
		// product-design
		// graphic-design
		// fashion-design
		// architecture
		// other
		category: {
			type: String,
			required: true,
		},
	},
	setup(props) {
		// Load artist
		let {
			loading,
			editMode,
			menuOptions,
			showConflict,
			conflictData,
			artist,
			altNames,
			byline,
			aboutClass,
			aboutSources,
			relatedArtists,
			googleUrl,
			tumblrUrl,
			pinterestUrl,
			hiddenArtistNote,
			//
			toggleEditMode,
			onArtistUpdate,
		} = loadContent(props)

		const feedStore = useFeedStore()

		// Assemble & inject meta data
		createMetaData(artist)

		return {
			loading,
			editMode,
			menuOptions,
			showConflict,
			conflictData,
			artist,
			altNames,
			byline,
			aboutClass,
			aboutSources,
			relatedArtists,
			googleUrl,
			tumblrUrl,
			pinterestUrl,
			hiddenArtistNote,
			//
			toggleEditMode,
			onArtistUpdate,
			//
			feedStore,
			//
			displayUrl,
		}
	},
}

/**
 * Load artist info
 */
function loadContent(props) {
	const pageArtistStore = usePageArtistStore()
	const httpErrorStore = useHttpErrorStore()
	const router = useRouter()
	const route = useRoute()

	// Load API
	const apiStore = useApiStore()
	const artistApi = apiStore.loadApi('artist')

	// Reactive data
	const state = reactive({
		loading: !isSSRClient,
		editMode: false,
		menuOptions: [
			{
				value: 'edit',
				display: 'Edit',
				onSelect: toggleEditMode,
			},
		],
		showConflict: false,
		conflictData: null,
		artist: computed(() => pageArtistStore.artist),
		altNames: computed(() => {
			if (!state.artist || !state.artist.altNames) return
			return (
				state.artist.altNames
					// Foreign name is show next to main name, so we don't repeat it as AKA.
					.filter(alt => {
						return alt.name
							? alt.name != state.artist.foreignName
							: `${alt.firstName} ${alt.lastName}` != state.artist.foreignName
					})
					.map(alt => {
						return alt.name ? `<b>${alt.name}</b>` : `<b>${alt.firstName} ${alt.lastName}</b>`
					})
					.join(' or ')
			)
		}),
		byline: computed(() => byline(state.artist)),
		category_namePath: computed(() => `${props.category}_${props.namePath}`),
		aboutClass: computed(() => {
			return state.artist && state.artist.about && state.artist.about.length > 90 ? 'block' : ''
		}),
		aboutSources: computed(() => {
			if (
				!state.artist ||
				!state.artist.aboutSources
				// ||  (state.artist.aboutSources.length == 1 && !state.artist.aboutSources[0]) // TODO: This is because we accidentally store empty string as source, needs to be fixed elsewhere,
			)
				return
			const sourcesHtml =
				'<br><br>Sources: ' +
				state.artist.aboutSources
					.map(src => `<a target="_blank" href="${src}">${displayUrl(src)}</a>`)
					.join(', ')
			return sourcesHtml
		}),
		googleUrl: computed(() => {
			const name = state.artist ? state.artist.name : ''
			return google(name)
		}),
		tumblrUrl: computed(() => {
			const name = state.artist ? state.artist.name : ''
			return tumblr(name)
		}),
		pinterestUrl: computed(() => {
			const name = state.artist ? state.artist.name : ''
			return pinterest(name)
		}),
		hiddenArtistNote: computed(() => {
			// prettier-ignore
			const died = state.artist ? state.artist.died : null
			const name = state.artist ? state.artist.name : ''
			const subject1 = died ? `${possessive(name)} estate` : 'The artist'
			const subject2 = died ? "the artist's" : 'their'
			return `${subject1} has requested to not have ${subject2} work displayed on Arthur.`
		}),
	})

	// Server-side initiation
	onServerPrefetch(_loadArtist)

	// Client-side initiation
	onMounted(() => {
		// Only load page if it wasn't already fetched server-side (development)
		const isHydrating = !!pageArtistStore.artist
		if (!isHydrating) _loadArtist()
	})

	onBeforeUnmount(() => {
		// Required to avoid the last visited itm to linger after you return from another page.
		pageArtistStore.$reset()
	})

	// Reload content whenever path changes
	const category_namePath = toRef(state, 'category_namePath')
	watch(category_namePath, () => {
		state.loading = true
		_loadArtist()
	})

	// Return data
	return { ...toRefs(state), toggleEditMode, onArtistUpdate }

	//
	//

	/**
	 * Exported functions
	 */

	// Toggle edit mode
	function toggleEditMode() {
		window.scrollTo(0, 0)
		state.editMode = !state.editMode
	}

	// When artist is successfully edited.
	async function onArtistUpdate(newArtist) {
		const { namePath, category } = newArtist
		const newRoute = _reroute()
		if (newRoute) {
			await router.push(newRoute)
		} else {
			_loadArtist()
		}
		flash(`You successfully updated this artist.`, { type: 'success' })
		toggleEditMode()

		// We might have to reroute.
		function _reroute() {
			const newCategory = state.artist.category != category
			const newNamePath = state.artist.namePath != namePath
			return newNamePath || newCategory ? { name: 'Artist', params: { namePath, category } } : null
		}
	}

	/**
	 * Local functions
	 */

	// Load artist data
	async function _loadArtist() {
		// console.log('_loadArtist')
		// Load artist
		const { status, data, statusText } = await artistApi.get({
			namePath: props.namePath,
			category: props.category,
		})
		if (status == 200) {
			if (data.conflict) {
				// Conflict: category doesn't match and there's
				// more than one artists with this namePath to fall back on.
				// Eg. /photography/joel-morrison
				state.showConflict = true
				state.conflictData = data
			} else if (data.category && data.category != props.category) {
				// The artist exists but the category is wrong --> Redirect.
				router.push({ name: 'Artist', params: { namePath: props.namePath, category: data.category } })
			} else {
				pageArtistStore.storeArtist(data)
			}
		} else if (status == 301) {
			// The artist's namePath has changed, forward to correct URL.
			const redirect = {
				name: 'Artist',
				category: props.category,
				params: { ...route.params },
				query: { ...route.query },
			}
			// 301 returns new namePath and category as data
			redirect.params.namePath = data
			router.push(redirect)
		} else {
			_httpError(status, statusText)
		}

		// Expand related artists
		if (state.artist && state.artist.related && state.artist.related.length) {
			const ids = state.artist.related.map(a => a.id)
			const { status, data, statusText } = await artistApi.expandIds(ids)
			if (status == 200 || status == 206) {
				state.artist.related = data.map((a, i) => ({
					...a,
					connection: state.artist.related[i].connection,
				}))
			} else {
				state.artist.related = undefined
			}
			if (status != 200) {
				handleApiError({ status, data, statusText, action: 'loading related artists' })
			}
		}

		state.loading = false
	}

	// Throw http error
	function _httpError(status, statusText) {
		httpErrorStore.setError({
			status,
			statusText,
			type: 'artist',
			origin: 'Artist.vue',
		})
	}
}

/**
 * Assemble & inject meta data
 */
// prettier-ignore
function createMetaData(artist) {
	// Construct meta data
	const title = computed(() => (artist.value ? artist.value.name : ''))
	const artworkCount = computed(() => artist.value ? artist.value.artworkCount : '')
	const description = computed(() => {
		if (artworkCount.value > 1) {
			return `Browse ${artworkCount.value} artworks by ${artist.value ? artist.value.name : 'this artist'} on Arthur.`
		} else {
			return 'Arthur is a digital museum.'
		}
	})

	const imgSrc = computed(() => artist.value && artist.value.previewArtwork ? 'https://arthur.io' + artist.value.previewArtwork.views[0].src.replace(/__SIZE__/, 'large') : null)
	const imgWidth = computed(() => artist.value && artist.value.previewArtwork ? artist.value.previewArtwork.views[0].width : null)
	const imgHeight = computed(() => artist.value && artist.value.previewArtwork ? artist.value.previewArtwork.views[0].height : null)

	const noIndex = computed(() => artist.value && artist.value.takenDown)

	// Inject meta data
	injectMetaData({ title, description, imgSrc, imgWidth, imgHeight, noIndex })
}

/**
 * Create byline with country, date of birth etc
 */
function byline(artist) {
	if (!artist || !artist) return ''

	const sep = ' &nbsp;&nbsp;•&nbsp;&nbsp; '
	const byline = []
	let dates
	const born = artist.born ? _html(displayYear(artist.born), 'birthDate') : null
	const died = artist.died ? _html(displayYear(artist.died), 'deathDate') : null
	const country = artist.country ? _html(artist.country, 'nationality') : null
	const website = artist.website
		? `<a target="_blank" href="${artist.website}">${displayUrl(artist.website)}</a>`
		: null
	const wikipedia = artist.wikipedia
		? `<a target="_blank" href="${artist.wikipedia}" itemprop="sameAs">Wikipedia</a>`
		: null
	if (born && died) {
		dates = [born, died].join(' - ')
	} else if (born) {
		dates = 'b. ' + born
	} else if (died) {
		dates = 'd. ' + died
	}
	if (dates) byline.push(dates)
	if (country) byline.push(country)
	if (website) byline.push(website)
	if (wikipedia) byline.push(wikipedia)
	return byline.join(sep)

	// Add
	function _html(value, itemprop) {
		return `<span ${itemprop ? 'itemprop="' + itemprop + '"' : ''}>${value}</span>`
	}
}
</script>

<style lang="scss" scoped>
#header {
	padding: 1.8rem var(--pp) 1rem var(--pp);
	max-width: 7rem;
	margin: 0 auto;
	text-align: center;
}
#header .aka {
	color: $black-50;
	margin: -0.15rem 0 0.2rem 0;
}
#header .byline {
	line-height: 0.26rem;
}
#header .about.block {
	max-width: 6.12rem;
	text-align: justify;
	margin: 0 auto;
	white-space: pre-wrap;
	word-break: break-word;
}
#header .related {
	margin-top: 0.3rem;
}
#header .notice {
	margin: 0.3rem 0;
	opacity: 0.3;
}
#loader-anim {
	padding-bottom: 2rem;
}

#gallery,
#gallery-empty {
	margin-top: 0;
}

// Wormhole
#wormhole {
	margin-top: -0.5rem;
	margin-bottom: 0.5rem;
}
</style>
