<template>
	<!-- Wrap needed for debug display -->
	<div class="wrap">
		<FormText
			v-model="data.value"
			:label="label"
			:placeholder="placeholder"
			:valid="data.valid"
			:autoFocus="autoFocus"
			:error="data.valid === false ? 'Invalid' : null"
			:disabled="disabled"
			@blur="onBlur"
		/>

		<DebugDisplay v-if="debug" :data="data" style="margin-top:0.05rem" />
	</div>
</template>

<script>
// Vue
import { nextTick } from 'vue'
// Components
import FormText from '@/components/FormText'
import DebugDisplay from '@/components/DebugDisplay'

// Internal
import { isUrl } from '@/use/Helpers'

export default {
	name: 'FormUrl',
	props: {
		modelValue: {
			type: String,
			default: null,
		},
		label: String,
		disabled: Boolean,
		placeholder: {
			type: String,
			default: 'http://...',
		},

		// This will auto focus as soon as element is rendered
		autoFocus: {
			type: Boolean,
			default: false,
		},

		// When a link is removed and the input blurred, we remove it from the UI.
		// But when we'd remove it from the data structure, this causes a bunch of problems,
		// eg. the error state of an input will be mismatched with the value because all
		// the values were moved one up. So it's easier to just hide the input and keep the
		// data structure intact. But we can't simply hide all empty inputs, because that
		// would also hide the input used to add new values. And because in the data object
		// we only store the string and no associated data, we have to use that string to tell
		// the interface to ignore it by setting the value to _EMPTY_.
		markEmpty: Boolean,

		// Custom data modifier: gets applied whenever
		// value is updated.
		customModifier: Function,

		// An additional field-specific validator,
		// eg. to validate wikipedia URLs
		customValidator: {
			type: Function,
			default: () => {
				return true
			},
		},

		debug: {
			type: [Boolean, Number],
			default: false,
		},
	},
	emits: ['update:modelValue', 'update', 'blur'],
	components: {
		FormText,
		DebugDisplay,
	},
	data() {
		return {
			data: {
				value: this.modelValue,
				valid: this.modelValue ? true : null,
			},
		}
	},
	computed: {
		// v-model
		// This value only stores valid url. When it's invalid this will return null.
		// Hence it is not used for inside the input, only to expose the url to the parent.
		theValue: {
			get() {
				return this.modelValue
			},
			set(val) {
				this.$emit('update', this.data) // Allows us to attach events to @update
				this.$emit('update:modelValue', val) // Required for v-model to work
			},
		},
	},
	watch: {
		// As soon as the value is edited, we remove all (in)validated data.
		'data.value'() {
			this.clearValidation()
		},
	},
	mounted() {
		// Emit data on initiation so it's available in the edit form.
		if (this.data.value) {
			this.update()
		}
	},
	methods: {
		// Update v-model value
		update() {
			if (this.data.value) {
				if (this.data.valid) {
					// Valid value
					this.theValue = this.data.value
				} else {
					// Invalid value
					this.theValue = null
				}
			} else {
				// Empty value
				this.theValue = this.markEmpty ? '_EMPTY_' : ''
			}
		},

		async onBlur() {
			if (!this.data.value) {
				this.update()
				this.$emit('blur', null)
				return this.clearValidation()
			}

			// Add http if not present. We try to catch typos to avoid
			// unwanted double http situations
			const hasHttp = this.data.value.match(/^http(s)?:/)
			const hasFaultyHttp = this.data.value.match(/^(htp|ttp|htt)s?:/)
			if (!hasHttp && !hasFaultyHttp) this.data.value = 'http://' + this.data.value.trim()

			// Wait for the value watcher to clear validation.
			await nextTick()

			// Custom data modification, used to trim wikipedia urls.
			if (this.customModifier) {
				this.data.value = this.customModifier(this.data.value)
			}

			this.validateUrl(this.data.value)
			this.$emit('blur', this.data.value)
		},

		validateUrl(url) {
			if (isUrl(url) && this.customValidator(url)) {
				this.data.valid = true
				this.update()
			} else {
				this.data.valid = false
				this.update()
			}
		},

		// Clear validation
		clearValidation() {
			this.data.valid = null
		},
	},
}
</script>

<style src="../assets/css/form-items.scss" lang="scss" scoped></style>
<style lang="scss" scoped></style>
