<template>
	<!-- <pre>{{ data }}</pre> -->
	<div id="flex-wrap">
		<div>
			<h1>Which Artist Are You Looking For?</h1>
			<p>
				There is no {{ requestedCategory }} named <i>{{ artistName }}</i
				>, but there's
				<template v-for="(category, i) in availableCategories" :key="category">
					{{ category.article }}
					<router-link :to="{ name: 'Home' }">{{ category.value }}</router-link>
					<template v-if="i < availableCategories.length - 1"> and </template>
				</template>
				with that same name.
			</p>
		</div>
	</div>
</template>

<script>
import artCategories from '@/use/ArtCategories'
import { article } from '@/use/StringMagic'
export default {
	name: 'ArtistFallbackConflict',
	props: {
		data: Object,
	},
	computed: {
		artistName() {
			return this.data.artists[0].name
		},
		requestedCategory() {
			return artCategories.personify(this.data.requestedCategory)
		},
		availableCategories() {
			let personifiedCategories = this.data.artists.map(a => artCategories.personify(a.category))
			const articles = personifiedCategories.map(c => article(c))
			personifiedCategories = personifiedCategories.map((c, i) => {
				return {
					article: articles[i], // a or an
					value: c, // artist/illustrator/etc
				}
			})
			return personifiedCategories
		},
	},
}
</script>

<style scoped lang="scss">
#flex-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	text-align: center;
}
#flex-wrap div {
	max-width: 4rem;
}
</style>
